
export const data = [
    {
      id:'1',
      head:'Head',
      body:'ips fjkfjd kfjdjjfd djrhta the boy wds tieys kduuieham uudtlk ahe boy ws not even going to tell the people that he was csoming  to hte event'
    },
    {
      id:'2',
      head:'Head',
      body:'ips fjkfjd kfjdjjfd djrhta the boy wds tieys kduuieham uudtlk ahe boy ws not even going to tell the people that he was csoming  to hte event'
    },
    {
      id:'3',
      head:'Head',
      body:'ips fjkfjd kfjdjjfd djrhta the boy wds tieys kduuieham uudtlk ahe boy ws not even going to tell the people that he was csoming  to hte event'
    },
    {
      id:'4',
      head:'Head',
      body:'ips fjkfjd kfjdjjfd djrhta the boy wds tieys kduuieham uudtlk ahe boy ws not even going to tell the people that he was csoming  to hte event'
    },
    {
      id:'5',
      head:'Head',
      body:'ips fjkfjd kfjdjjfd djrhta the boy wds tieys kduuieham uudtlk ahe boy ws not even going to tell the people that he was csoming  to hte event'
    },
  ]