export const state_data = [
    {state:'Anambra'},
    {state:'Anambra'},
    {state:'Anambra'},
    {state:'Anambra'},
  ];
 export const family_data = [
    {family:'Mr. & Mrs. Daniel'},
    {family:'Mr. & Mrs. Obinwanney'},
    {family:'Mr. & Mrs. Daniel'},
  ]